var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SessionFrame',{attrs:{"title":"Create New Address","image":"new"}},[_c('TmFormStruct',{attrs:{"submit":_vm.onSubmit.bind(this)}},[_c('h2',{staticClass:"session-title"},[_vm._v(" Create a new address ")]),(_vm.session.insecureMode)?_c('div',{staticClass:"session-main"},[_c('div',{staticClass:"danger-zone"},[_c('h2',[_vm._v("DANGER ZONE")]),_c('p',[_vm._v(" Creating an address in the browser is not advised. This feature is only enabled in insecure mode for testing purposes and should not be used otherwise. ")])]),_c('TmFormGroup',{attrs:{"error":_vm.$v.fields.signUpName.$error,"field-id":"sign-up-name","field-label":"Account Name"}},[_c('TmField',{attrs:{"id":"sign-up-name","type":"text","placeholder":"Must be at least 5 characters","vue-focus":"vue-focus"},model:{value:(_vm.fields.signUpName),callback:function ($$v) {_vm.$set(_vm.fields, "signUpName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fields.signUpName"}}),(_vm.$v.fields.signUpName.$error && !_vm.$v.fields.signUpName.required)?_c('TmFormMsg',{attrs:{"name":"Name","type":"required"}}):_vm._e(),(
            _vm.$v.fields.signUpName.$error && !_vm.$v.fields.signUpName.minLength
          )?_c('TmFormMsg',{attrs:{"name":"Name","type":"minLength","min":"5"}}):_vm._e()],1),_c('TmFormGroup',{attrs:{"error":_vm.$v.fields.signUpPassword.$error,"field-id":"sign-up-password","field-label":"Password"}},[_c('TmField',{attrs:{"id":"sign-up-password","type":"password","placeholder":"Must be at least 10 characters"},model:{value:(_vm.fields.signUpPassword),callback:function ($$v) {_vm.$set(_vm.fields, "signUpPassword", $$v)},expression:"fields.signUpPassword"}}),(
            _vm.$v.fields.signUpPassword.$error &&
              !_vm.$v.fields.signUpPassword.required
          )?_c('TmFormMsg',{attrs:{"name":"Password","type":"required"}}):_vm._e(),(
            _vm.$v.fields.signUpPassword.$error &&
              !_vm.$v.fields.signUpPassword.minLength
          )?_c('TmFormMsg',{attrs:{"name":"Password","type":"minLength","min":"10"}}):_vm._e()],1),_c('TmFormGroup',{attrs:{"error":_vm.$v.fields.signUpPasswordConfirm.$error,"field-id":"sign-up-password-confirm","field-label":"Confirm Password"}},[_c('TmField',{attrs:{"id":"sign-up-password-confirm","type":"password","placeholder":"Enter password again"},model:{value:(_vm.fields.signUpPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.fields, "signUpPasswordConfirm", $$v)},expression:"fields.signUpPasswordConfirm"}}),(
            _vm.$v.fields.signUpPasswordConfirm.$error &&
              !_vm.$v.fields.signUpPasswordConfirm.sameAsPassword
          )?_c('TmFormMsg',{attrs:{"name":"Password confirmation","type":"match"}}):_vm._e()],1),_c('TmFormGroup',{staticClass:"sign-up-seed-group",attrs:{"field-id":"sign-up-seed","field-label":"Seed Phrase"}},[_c('FieldSeed',{attrs:{"id":"sign-up-seed"},model:{value:(_vm.fields.signUpSeed),callback:function ($$v) {_vm.$set(_vm.fields, "signUpSeed", $$v)},expression:"fields.signUpSeed"}})],1),_c('TmFormGroup',{staticClass:"field-checkbox",attrs:{"error":_vm.$v.fields.signUpWarning.$error,"field-id":"sign-up-warning","field-label":""}},[_c('div',{staticClass:"field-checkbox-input"},[_c('label',{staticClass:"field-checkbox-label",attrs:{"for":"sign-up-warning"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.signUpWarning),expression:"fields.signUpWarning"}],attrs:{"id":"sign-up-warning","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fields.signUpWarning)?_vm._i(_vm.fields.signUpWarning,null)>-1:(_vm.fields.signUpWarning)},on:{"change":function($event){var $$a=_vm.fields.signUpWarning,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fields, "signUpWarning", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fields, "signUpWarning", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fields, "signUpWarning", $$c)}}}}),_vm._v(" I understand that lost seeds cannot be recovered.")])]),(
            _vm.$v.fields.signUpWarning.$error &&
              !_vm.$v.fields.signUpWarning.required
          )?_c('TmFormMsg',{attrs:{"name":"Recovery confirmation","type":"required"}}):_vm._e()],1),_c('div',{staticClass:"session-footer"},[_c('TmBtn',{attrs:{"value":"Create Address"}})],1)],1):_vm._e(),(!_vm.session.insecureMode)?_c('div',{staticClass:"session-main"},[(!_vm.extension.enabled)?_c('p',[_vm._v(" Creating an address in the browser is unsafe. Please install "),_c('a',{attrs:{"target":"_blank","href":"https://www.mojaik.com/feechain-document"}},[_vm._v("Fee Wallet")])]):_vm._e(),_c('router-link',{attrs:{"to":"existing"}},[_vm._v("Want to use an existing address?")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }